export enum AppNames {
  CRM = 'crm-app',
  PLATFORM_WEB = 'platform-web-app',
  INVESTOR_PORTAL = 'investor-portal',
  CRM_DOCUMENTS = 'crm-documents',
  AUTH = 'authenticator'
}

export const AppNamesTitleLabels = {
  [AppNames.CRM]: 'CRM',
  [AppNames.PLATFORM_WEB]: 'POEMS',
  [AppNames.INVESTOR_PORTAL]: 'Investor Portal',
  [AppNames.CRM_DOCUMENTS]: 'Documents',
  [AppNames.AUTH]: 'Authenticator'
};
