import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import { ClientFile } from '@pinnakl/shared/types';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { DataroomDocument } from '@pinnakl/vdr/domain';
import moment from 'moment';
import { EMPTY, Observable, catchError, concatMap, firstValueFrom, map, race, timer } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { CurrentUserStore } from './current-user';

export const fileUrl = { url: '' };

type ClientFileFromApi = ClientFile & {
  createDate: string;
  updateDate: string;
};

interface UploadArguments {
  file: File;
  osType?: string;
  osTypeId?: number;
  folderId?: number;
  portalUserClientMembershipId?: number;
  replaceFileId?: number | null;
}

type GetStatusResponse = {
  publicUrl?: string;
  location?: string;
  status: 'Completed' | 'In Progress';
};

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private readonly currentUserStore = inject(CurrentUserStore);
  _RESOURCE_URL;

  constructor(
    private readonly _http: HttpClient,
    private readonly toastr: PinnaklUIToastMessage,
    @Inject(ENVIRONMENT_SERVICE) private readonly environmentService: EnvironmentService
  ) {
    this._RESOURCE_URL = this.environmentService.get('fileServiceUrl');
    if (this._RESOURCE_URL === '') {
      this._RESOURCE_URL = fileUrl.url;
    }
  }

  async delete(id: number): Promise<void> {
    const requestUrl = `${this._RESOURCE_URL}Delete/${id}`;
    return firstValueFrom(this._http.get<void>(requestUrl));
  }

  getAtdlFile(brokerCode: string, assetType: string): Observable<any> {
    const requestUrl = `${this._RESOURCE_URL}AtdlFile?brokerCode=${brokerCode}&assetType=${assetType}`;
    return this._http
      .get<any>(requestUrl, { observe: 'response', responseType: 'text' } as any)
      .pipe(map(httpResponse => httpResponse['body']));
  }

  getUploadTemplate(): Observable<any> {
    const requestUrl = `${this._RESOURCE_URL}AtdlFile?brokerCode=OMS&assetType=equity`;
    return this.processDownload(requestUrl);
  }

  download(id: number, queryParams?: string): Observable<void> {
    const requestUrl = `${this._RESOURCE_URL}Download/${id}${queryParams ? queryParams : ''}`;
    return this.processDownload(requestUrl);
  }

  downloadFromPortal(id: number, clientMembershipId: number): Observable<void> {
    const requestUrl = `${this._RESOURCE_URL}DownloadFromPortal?id=${id}&portalUserClientMembershipId=${clientMembershipId}&target=download`;
    return this.processDownload(requestUrl);
  }

  downloadFolderFromPortal(
    folderId: number,
    clientMembershipId: number
  ): Observable<string | null> {
    const requestUrl = `${this._RESOURCE_URL}DownloadFolderFromPortal?folderId=${folderId}&portalUserClientMembershipId=${clientMembershipId}&target=download`;
    return this._http.get(requestUrl, { observe: 'response' }).pipe(
      switchMap((response: HttpResponse<unknown>) => {
        const location = response.headers.get('Location');
        if (!location)
          throw new Error(
            'Access to Location header is not allowed, check Access-Control-Expose-Headers.'
          );
        const statusUrl = location?.replace(/\/File\//, ''); // TODO: fix response and remove
        const statusRequest = this._http.get<GetStatusResponse>(
          `${this._RESOURCE_URL}${statusUrl}`
        );
        return race(
          timer(0, 1000).pipe(
            concatMap(() => statusRequest),
            filter(res => res.status === 'Completed'),
            map(res => res.publicUrl as string)
          ),
          timer(60 * 1000).pipe(map(() => null))
        );
      }),
      take(1)
    );
  }

  processDownload(requestUrl: string): Observable<void> {
    return this._http.get(requestUrl, { observe: 'response', responseType: 'blob' }).pipe(
      map(httpResponse => {
        const { body, headers } = httpResponse;
        const fileName = headers.get('fileName');
        body && fileName && this.handleDownloadResponse(body, fileName);
      }),
      catchError((response: HttpErrorResponse) => {
        this.handleDownloadError(response);
        return EMPTY;
      })
    );
  }

  handleDownloadResponse(blob: Blob, fileName: string): void {
    const fileUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', fileUrl);
    anchor.setAttribute('download', fileName);
    anchor.click();
  }

  handleDownloadError(response: HttpErrorResponse): void {
    const blob: Blob = response.error;
    blob.text().then(error => {
      this.toastr.error(JSON.parse(error));
    });
  }

  async getFiles({
    osType,
    osTypeId,
    taskInstanceQueueId
  }: {
    osType?: string;
    osTypeId?: number;
    taskInstanceQueueId?: number;
  }): Promise<ClientFile[]> {
    const filters: string[] = [];
    if (osType) {
      filters.push(`osType=${osType}`);
    }
    if (osTypeId) {
      filters.push(`osTypeId=${osTypeId}`);
    }
    if (taskInstanceQueueId) {
      filters.push(`taskInstanceQueueId=${taskInstanceQueueId}`);
    }
    if (!filters.length) {
      throw new Error('Invalid filters');
    }
    const criteria = filters.length === 1 ? filters[0] : filters.join('&');
    const url = `${this._RESOURCE_URL}GetFiles?${criteria}`;
    const clientFiles = await this._http.get<ClientFileFromApi[]>(url).toPromise();
    return (clientFiles ?? []).map(this._formatClientFile);
  }

  async upload({
    file,
    osType,
    osTypeId,
    folderId,
    replaceFileId
  }: UploadArguments): Promise<DataroomDocument> {
    const url = `${this._RESOURCE_URL}Upload`;
    const formData = this._createUploadFormData({
      file,
      osType,
      osTypeId,
      folderId,
      replaceFileId
    });
    const clientFile = await this._http.post<ClientFileFromApi>(url, formData).toPromise();
    if (!clientFile) {
      throw new Error('Client file not found');
    }
    return this._formatClientFile(clientFile);
  }

  async uploadFromPortal({
    file,
    folderId,
    portalUserClientMembershipId
  }: UploadArguments): Promise<DataroomDocument> {
    const url = `${this._RESOURCE_URL}UploadFromPortal`;
    const formData = this._createUploadFormData({
      file,
      folderId,
      portalUserClientMembershipId
    });
    const headers = this._createHeaders();
    const clientFile = await this._http
      .post<ClientFileFromApi>(url, formData, { headers })
      .toPromise();
    if (!clientFile) {
      throw new Error('Client file not found');
    }
    return this._formatClientFile(clientFile);
  }

  private _createHeaders(): HttpHeaders {
    const user = this.currentUserStore.currentUserInfo();

    if (user?.token) {
      return new HttpHeaders({ token: user.token });
    }
    return new HttpHeaders();
  }

  private _createUploadFormData({
    file,
    osType,
    osTypeId,
    folderId,
    portalUserClientMembershipId,
    replaceFileId
  }: UploadArguments): FormData {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (osType) {
      formData.append('osType', osType);
    }
    if (osTypeId) {
      formData.append('osTypeId', osTypeId.toString());
    }
    if (folderId) {
      formData.append('folderId', folderId.toString());
    }
    if (portalUserClientMembershipId) {
      formData.append('portalUserClientMembershipId', portalUserClientMembershipId.toString());
    }
    if (replaceFileId != undefined) {
      formData.append('replaceFileId', replaceFileId.toString());
    }
    return formData;
  }

  private _formatClientFile(entityFromApi: ClientFileFromApi): DataroomDocument {
    return {
      ...entityFromApi,
      createDate: moment.utc(new Date(Date.parse(`${entityFromApi.createDate}`))).toDate(),
      updateDate: entityFromApi.updateDate ? new Date(entityFromApi.updateDate) : null
    } as DataroomDocument;
  }
}
