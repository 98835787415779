import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentFills, ComponentSizes } from '@pinnakl/shared/types';
import { OverlayOptions } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedSingleDropdownComponent } from './control-injected-single-dropdown.component';

const DefaultMultiSelectSize = 'md';
const imports = [CommonModule, DropdownModule, ReactiveFormsModule];

@Component({
  imports,
  selector: 'prime-single-dropdown',
  templateUrl: './prime-single-dropdown.component.html',
  styleUrls: ['./prime-single-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeSingleDropdownComponent extends ControlInjectedSingleDropdownComponent {
  // custom `fill` input for theming, variant is not being utilized
  @Input() fill: ComponentFills = 'outlined';
  @Input() size: ComponentSizes = DefaultMultiSelectSize;
  @Input() override overlayOptions!: OverlayOptions;

  @ContentChild('validationContent', { static: false })
  validationContent: TemplateRef<null> | null = null;
  @ContentChild('selectedItemTemplate', { static: false })
  selectedItemTemplate: TemplateRef<null> | null = null;
  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<null> | null = null;
  @ContentChild('dropdownIconTemplate', { static: false })
  dropdownIconTemplate: TemplateRef<null> | null = null;
  @ContentChild('dropdownPlaceholderIconTemplate', { static: false })
  dropdownPlaceholderIconTemplate: TemplateRef<null> | null = null;
  @ContentChild('footerTemplate', { static: false })
  footerTemplate: TemplateRef<null> | null = null;
  @ContentChild('emptyTemplate', { static: false })
  emptyTemplate: TemplateRef<null> | null = null;

  get dropdownClasses(): string {
    return `fill-${this.fill} size-${this.size}`;
  }

  @HostBinding('class') get componentClasses(): string {
    return 'prime-single-dropdown';
  }

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);

    this.appendTo = 'body';
  }
}
