import { Inject, Injectable, Optional } from '@angular/core';
import { EnvNames } from './environment-names.enum';
import { PinnaklEnvironment } from './environment.model';
import { ENVIRONMENT_CONFIG } from './environment.tokens';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _environment?: PinnaklEnvironment;

  get isDevEnv(): boolean {
    return this._environment?.envName === EnvNames.dev;
  }

  get isProdEnv(): boolean {
    return this._environment?.envName === EnvNames.prod;
  }

  get environment(): PinnaklEnvironment | undefined {
    return this._environment;
  }

  constructor(@Optional() @Inject(ENVIRONMENT_CONFIG) environmentConfig?: PinnaklEnvironment) {
    environmentConfig && this.registerEnvironment(environmentConfig);
  }

  registerEnvironment(config: PinnaklEnvironment): void {
    this._environment = config;
  }

  get<T = any>(key: keyof PinnaklEnvironment): T {
    if (!this.environment) {
      throw new Error('Environment was not defined.');
    }
    if (this.environment[key] == null) {
      console.error(
        `Requested property [${key}] does not not exist in [${this._environment?.envName}] environment configuration.`,
        [EnvNames.dev, EnvNames.staging].includes(this.environment.envName)
          ? this._environment
          : undefined
      );
    }
    return this.environment[key] as T;
  }
}
