import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'route-resolver',
  templateUrl: './route-resolver.component.html',
  styleUrls: ['./route-resolver.component.scss']
})
export class RouteResolverComponent implements OnInit {
  title: string;
  headerClass: string;
  defaultTitleTab: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    const { data, params } = this.route.snapshot,
      { title, headerClass, resolvingPath, defaultTitleTab } = data as {
        title: string;
        headerClass: string;
        resolvingPath: string;
        loadingTitleTab: boolean;
        titleTab: string;
        defaultTitleTab: string;
      };
    this.title = title;
    this.headerClass = headerClass;
    this.defaultTitleTab = defaultTitleTab;
    if (resolvingPath) {
      setTimeout(() => {
        this.router.navigate([resolvingPath, params], {
          skipLocationChange: true
        });
      });
    } else {
      throw new Error('No resolving path provided');
    }
  }
}
