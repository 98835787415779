import { CustomError } from './error.model';

export enum AmplitudeCRMFeatureUsedName {
  // ----- General
  DashboardOrganizationDrillDown = 'Dashboard organization drill down',
  DashboardContactDrillDown = 'Dashboard contact drill down',
  DashboardCampaignResultsDrillDown = 'Dashboard campaign results drill down',
  GeoSearch = 'Geo search',
  FundPerformanceDateRangeChanged = 'Fund performance date range changed',
  FundPerformanceFundSelected = 'Fund performance fund selected',
  ReportOpened = 'Report opened',

  // ----- Tasks
  CreateTask = 'Create task',
  EditTask = 'Edit task',

  // ----- Communications
  CreateNote = 'Create note',
  CreateCommunication = 'Create communication',
  NotifyCommunications = 'Notify communications',
  DateRangeFilterAppliedToCommunications = 'Date range filter applied to communications',
  TagFilterAppliedToCommunications = 'Tag filter applied to communications',
  InvestorFilterAppliedToCommunications = 'Investor filter applied to communications',

  // ----- Search
  SearchRequest = 'Search request',
  SearchResultItemClick = 'Search result item click',
  SearchRecentItemClick = 'Search recent item click',

  // ----- Contacts
  CreateContact = 'Create contact',
  EditContact = 'Edit contact',
  AdvancedSearchContacts = 'Advanced search contacts',
  ColumnsConfiguringContacts = 'Columns configuring contacts',
  BulkUpdateContacts = 'Bulk update contacts',

  // ----- Organizations
  CreateOrganization = 'Create organization',
  EditOrganization = 'Edit organization',
  AdvancedSearchOrganizations = 'Advanced search organizations',
  ColumnsConfiguringOrganizations = 'Columns configuring organizations',
  BulkUpdateOrganizations = 'Bulk update organizations',
  PinFilterAppliedOnOrganizationDetails = 'Pin filter applied on organization details',
  DocumentFilterAppliedOnOrganizationDetails = 'Document filter applied on organization details',
  ContactsTabOpenedOnOrganizationDetails = 'Contacts tab opened on organization details',
  ExportOrganizationClicked = 'Export organization clicked',
  LinkOrganizationChanged = 'Link organization changed',
  LinkOrganizationFullPopupViewed = 'Link organization full popup viewed',

  // ----- Deals
  CreateDeal = 'Create deal',
  EditDeal = 'Edit deal',
  CreatePipeline = 'Create pipeline',

  // ----- VDR
  CreateVdrUser = 'VDR Create user',
  EditVdrUser = 'VDR Edit user',
  UploadVdrFile = 'VDR Upload file',
  OpenVdrUserAnalytics = 'VDR Open user analytics',
  OpenVdrDocumentAnalytics = 'VDR Open document analytics'
}

export enum AmplitudePOEMSFeatureUsedName {
  // ----- Trades
  CreateTrade = 'Create trade'
}

export type AmplitudeFeatureUsedName = AmplitudeCRMFeatureUsedName | AmplitudePOEMSFeatureUsedName;

export enum AmplitudeCRMEventTypes {
  HttpError = '[Pinnakl] [CRM] [HTTP Error]', // the error thrown by API
  ClientError = '[Pinnakl] [CRM] [Client Error]', // the error thrown by the client app manually
  GlobalError = '[Pinnakl] [CRM] [Global Error]', // the error thrown by the client app and caught on the highest global level
  FeatureUsed = '[Pinnakl] [CRM] [Feature Used]' // the feature that was used by the user
}

export enum AmplitudePOEMSEventTypes {
  HttpError = '[Pinnakl] [POEMS] [HTTP Error]', // the error thrown by API
  ClientError = '[Pinnakl] [POEMS] [Client Error]', // the error thrown by the client app manually
  GlobalError = '[Pinnakl] [POEMS] [Global Error]', // the error thrown by the client app and caught on the highest global level
  FeatureUsed = '[Pinnakl] [POEMS] [Feature Used]' // the feature that was used by the user
}

export type AmplitudeEventTypes = AmplitudeCRMEventTypes | AmplitudePOEMSEventTypes;

export type AmplitudeFeatureUsedEventPropertiesDetails = Record<
  string,
  string | number | boolean | null | undefined | Record<string, any>
> & { developerComment?: string };

export interface AmplitudeFeatureUsedEventProperties {
  name: AmplitudeFeatureUsedName;
  location: string;
  properties: AmplitudeFeatureUsedEventPropertiesDetails;
}

export interface AmplitudeErrorEventProperties {
  location: string;
  error: CustomError;
  sameErrorsInSessionCount: number;
}

export interface AmplitudeHttpErrorEventProperties extends AmplitudeErrorEventProperties {
  url: string;
  parsed_error: string;
}
