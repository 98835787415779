import { Pipe, PipeTransform } from '@angular/core';
import { LOCALE_COMPARE_OPTIONS } from '@pinnakl/shared/constants';
import get from 'lodash/get';

@Pipe({
  name: 'sortBy',
  standalone: true
})
export class SortByPipe implements PipeTransform {
  transform(
    value: any[],
    order: 'asc' | 'desc' = 'asc',
    column: string | string[] = '',
    customSorting?: (a, b) => number
  ): any[] {
    if (!Array.isArray(value)) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (!column || column === '' || column.length === 0) {
      if (order === 'asc') {
        return [...value].sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', LOCALE_COMPARE_OPTIONS);
          }
          return a - b;
        });
      } else {
        return [...value]
          .sort((a, b) => {
            if (typeof a === 'string' && typeof b === 'string') {
              return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', LOCALE_COMPARE_OPTIONS);
            }
            return a - b;
          })
          .reverse();
      }
    }
    const sortedArrayWithObjects = [...value].sort((a, b) => {
      if (Array.isArray(column)) {
        for (const col of column) {
          if (typeof get(a, col) === 'boolean') {
            if (get(a, col) > get(b, col)) {
              return -1;
            }
            if (get(a, col) < get(b, col)) {
              return 1;
            }
          }
          if (typeof get(a, col) === 'string' && typeof get(b, col) === 'string') {
            const result = get(a, col).localeCompare(get(b, col), 'en', LOCALE_COMPARE_OPTIONS);
            if (result !== 0) {
              return result;
            }
          }
          if (get(a, col) instanceof Date) {
            if (get(a, col).getTime() > get(b, col).getTime()) {
              return 1;
            }
            if (get(a, col).getTime() < get(b, col).getTime()) {
              return -1;
            }
          }
        }
        return 0;
      } else {
        if (typeof get(a, column) === 'boolean') {
          return get(a, column) > get(b, column) ? 1 : -1;
        }
        if (typeof get(a, column) === 'string') {
          return get(a, column).localeCompare(get(b, column), 'en', LOCALE_COMPARE_OPTIONS);
        }
        if (get(a, column) instanceof Date) {
          return get(a, column).getTime() > get(b, column).getTime() ? 1 : -1;
        }
      }
    });

    if (order === 'asc') {
      return sortedArrayWithObjects;
    } else {
      return sortedArrayWithObjects.reverse();
    }
  }
}
