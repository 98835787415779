import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentSizes } from '@pinnakl/shared/types';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedSelectButtonComponent } from './control-injected-select-button.component';

export type PrimeSelectButtonVariants =
  | 'default'
  | 'separated'
  | 'tabs'
  | 'filter'
  | 'filter-inverted'
  | 'outlined-filter';

@Component({
  imports: [CommonModule, SelectButtonModule, ReactiveFormsModule, FormsModule, CheckboxModule],
  standalone: true,
  selector: 'prime-select-button',
  templateUrl: 'prime-select-button.component.html',
  styleUrls: ['prime-select-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimeSelectButtonComponent extends ControlInjectedSelectButtonComponent {
  @Input() noBorders = false;
  @Input() isFullWidth = false;
  @Input() size: ComponentSizes = 'md';
  @Input() variant?: PrimeSelectButtonVariants = 'default';
  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<null> | null = null;

  @HostBinding('class.select-button-size-sm') get isSmallSelectButtonSize(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.stretch-full-width') get fullWidthClass(): boolean {
    return this.isFullWidth;
  }

  @HostBinding('class.items-no-borders') get noBordersClass(): boolean {
    return this.noBorders;
  }

  @HostBinding('class.separated') get separatedClass(): boolean {
    return this.variant === 'separated';
  }

  @HostBinding('class.tabs') get tabsClass(): boolean {
    return this.variant === 'tabs';
  }

  @HostBinding('class.filter') get filterClass(): boolean {
    return this.variant === 'filter';
  }

  @HostBinding('class.filter-inverted') get filterInvertedClass(): boolean {
    return this.variant === 'filter-inverted';
  }

  @HostBinding('class.outlined-filter') get outlinedFilterClass(): boolean {
    return this.variant === 'outlined-filter';
  }

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
