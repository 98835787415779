import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@pinnakl/core/environment';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  AppNames,
  CurrentUserInfoFromApi,
  CurrentUserInfoParsed,
  CurrentUserInfoUser,
  CurrentUserInfoUserFromApi
} from '@pinnakl/shared/types';
import { BuildUserRolesMap, getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrentUserUpdatePayload } from '../current-user';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserApiService {
  private readonly isPortal =
    inject(EnvironmentService).get('appName') === AppNames.INVESTOR_PORTAL;
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly baseUrl = inject(EnvironmentService).get('authConfig')?.authority;
  private readonly usersEndpoint = 'v4/users';

  async getCurrentUserInfo(accessToken?: string): Promise<CurrentUserInfoParsed> {
    try {
      return await firstValueFrom(this.loadUserInfo(accessToken));
    } catch (e) {
      if ((e as HttpErrorResponse).status === 401 || (e as HttpErrorResponse).status === 403) {
        throw e;
      }
      this.toast.error('Cannot load user info. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async updateUserInfo(userUpdateData: CurrentUserUpdatePayload): Promise<CurrentUserInfoUser> {
    try {
      await firstValueFrom(this.updateCurrentUser(userUpdateData));
      return await firstValueFrom(this.getUser());
    } catch (e) {
      this.toast.error('Cannot update user. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async changePassword(password: string): Promise<void> {
    return this.wsp.putHttp({
      endpoint: `${this.usersEndpoint}/password`,
      body: { password }
    });
  }

  private loadUserInfo(accessToken?: string): Observable<CurrentUserInfoParsed> {
    const headers = accessToken
      ? {
          Authorization: `Bearer ${accessToken}`
        }
      : undefined;
    return this.wsp
      .get<CurrentUserInfoFromApi>({
        endpoint: 'connect/userinfo',
        customHostUrl: this.baseUrl,
        headers
      })
      .pipe(
        switchMap(userInfo => {
          // todo: temporary fix for pinnakl portal. will not be needed when user info user will be the same as users api response
          return this.isPortal
            ? of({
                ...userInfo,
                user: {
                  ...userInfo.user,
                  fullName: `${userInfo.user.firstName} ${userInfo.user.lastName}`,
                  rolesMap: BuildUserRolesMap(userInfo.user.roles)
                }
              })
            : this.getUser(headers).pipe(
                map(user => ({
                  ...userInfo,
                  user: {
                    ...userInfo.user,
                    ...user
                  }
                }))
              );
        })
      );
  }

  private getUser(headers?: Record<string, string>): Observable<CurrentUserInfoUser> {
    return this.wsp
      .get<CurrentUserInfoUserFromApi>({
        endpoint: this.usersEndpoint,
        headers
      })
      .pipe(
        map(user => ({
          ...user,
          rolesMap: BuildUserRolesMap(user.roles)
        }))
      );
  }

  private updateCurrentUser(body: CurrentUserUpdatePayload): Observable<any> {
    return this.wsp.put({
      endpoint: this.usersEndpoint,
      body
    });
  }
}
