import { ErrorHandler, inject, Injectable } from '@angular/core';
import { AmplitudeCRMEventTypes } from '@pinnakl/shared/types';
import { AmplitudeEventsCollector } from '@pinnakl/shared/util-external-services';
import { getErrorObject } from '@pinnakl/shared/util-helpers';

const ERROR_THROTTLE_TIME_SECONDS = 60;

@Injectable({
  providedIn: 'root'
})
export class PinnaklErrorHandler implements ErrorHandler {
  private readonly errorsMapDatetime = new Map<string, Date>();
  private readonly errorsMapCount = new Map<string, number>();

  private readonly amplitudeEventsCollector = inject(AmplitudeEventsCollector);

  handleError(error: any): void {
    console.error('[PinnaklErrorHandler] Error', error);
    const lastErrorDatetime = this.errorsMapDatetime.get(error.message);
    if (lastErrorDatetime) {
      // if same error was thrown more than ERROR_THROTTLE_TIME_SECONDS seconds ago we track error
      if (Date.now() - lastErrorDatetime.getTime() > ERROR_THROTTLE_TIME_SECONDS * 1000) {
        this.amplitudeEventsCollector.track(AmplitudeCRMEventTypes.GlobalError, {
          sameErrorsInSessionCount: this.errorsMapCount.get(error.message) ?? 0,
          location: location.href,
          error: getErrorObject(error)
        });
      }
    } else {
      this.errorsMapDatetime.set(error.message, new Date());
      this.amplitudeEventsCollector.track(AmplitudeCRMEventTypes.GlobalError, {
        sameErrorsInSessionCount: this.errorsMapCount.get(error.message) ?? 0,
        location: location.href,
        error: getErrorObject(error)
      });
    }

    this.errorsMapCount.set(error.message, (this.errorsMapCount.get(error.message) ?? 0) + 1);
  }
}
