import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentSizes } from '@pinnakl/shared/types';
import { InputNumberModule } from 'primeng/inputnumber';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedInputNumberComponent } from './control-injected-input-number.component';

const USANumberFormat = 'en-US';

@UntilDestroy()
@Component({
  selector: 'prime-number-input',
  templateUrl: './prime-input-number.component.html',
  styleUrls: ['./prime-input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, InputNumberModule, ReactiveFormsModule],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeInputNumberComponent extends ControlInjectedInputNumberComponent {
  /**
   * Style class of the container.
   * @group Props
   */
  @Input() containerClass!: string;
  @Input() inputSize: ComponentSizes = 'md';

  @HostBinding('class') get hostClasses(): string {
    return this.inputSize === 'sm' ? 'size-sm' : '';
  }

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
    this.locale = USANumberFormat;
  }
}
