import * as amplitude from '@amplitude/analytics-browser';
import { LogLevel } from '@amplitude/analytics-types';
import { inject, Injectable } from '@angular/core';
import {
  EnvironmentService,
  PinnaklEnvironmentAmplitudeConfiguration
} from '@pinnakl/core/environment';
import {
  AmplitudeCRMEventTypes,
  AmplitudeErrorEventProperties,
  AmplitudeFeatureUsedEventProperties,
  AmplitudeHttpErrorEventProperties,
  AmplitudePOEMSEventTypes
} from '@pinnakl/shared/types';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeEventsCollector {
  private readonly environmentService = inject(EnvironmentService);
  private isInitialized = false;

  initialize(userEmail: string, logLevel: LogLevel = LogLevel.Debug): void {
    const configuration =
      this.environmentService.get<PinnaklEnvironmentAmplitudeConfiguration>(
        'amplitudeConfiguration'
      );
    if (!configuration) {
      throw new Error('No Amplitude configuration!');
    }
    amplitude
      .init(configuration.apiKey, userEmail, {
        logLevel,
        flushIntervalMillis: 5000,
        autocapture: {
          attribution: false,
          pageViews: true,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
          elementInteractions: false
        }
      })
      .promise.then(() => (this.isInitialized = true));
  }

  track(
    eventType: AmplitudeCRMEventTypes.GlobalError | AmplitudePOEMSEventTypes.GlobalError,
    eventProperties: AmplitudeErrorEventProperties
  ): void;
  track(
    eventType: AmplitudeCRMEventTypes.ClientError | AmplitudePOEMSEventTypes.ClientError,
    eventProperties: AmplitudeErrorEventProperties
  ): void;
  track(
    eventType: AmplitudeCRMEventTypes.HttpError | AmplitudePOEMSEventTypes.HttpError,
    eventProperties: AmplitudeHttpErrorEventProperties
  ): void;
  track(
    eventType: AmplitudeCRMEventTypes.FeatureUsed | AmplitudePOEMSEventTypes.FeatureUsed,
    eventProperties: AmplitudeFeatureUsedEventProperties
  ): void;
  track(
    eventType: AmplitudeCRMEventTypes | AmplitudePOEMSEventTypes,
    eventProperties:
      | AmplitudeErrorEventProperties
      | AmplitudeHttpErrorEventProperties
      | AmplitudeFeatureUsedEventProperties
  ): void {
    if (this.isInitialized) {
      amplitude.track({
        event_type: eventType,
        event_properties: eventProperties
      });
    }
  }
}
