<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-confirmDialog key="global"></p-confirmDialog>
<nav class="navbar navbar-fixed-top">
  <div class="navbar-inner-holder">
    <a class="navbar-brand">
      <img
        alt=""
        src="/assets/images/elements/site-logo.svg"
      />
    </a>
  </div>

  <div
    class="flex flex-row flex-nowrap"
    id="main-nav"
  >
    <div class="flex align-items-center justify-content-center">
      <!--  Header actions -->
      <prime-button
        (onClick)="searchModalOpen()"
        [link]="true"
        [text]="true"
        size="sm"
      >
        <ng-template #iconTemplate>
          <i
            class="text-bluegray-600 flex h-1_25rem w-1_25rem"
            inlineSVG="sprites.svg#search"
          ></i>
        </ng-template>
      </prime-button>

      <prime-button
        [link]="true"
        [text]="true"
        routerLink="/help"
        size="sm"
      >
        <ng-template #iconTemplate>
          <i
            class="text-bluegray-600 flex h-1_25rem w-1_25rem"
            inlineSVG="sprites.svg#exclamation-mark"
          ></i>
        </ng-template>
      </prime-button>
      <div
        #notificationBtn
        class="relative"
      >
        <prime-button
          (onClick)="notificationMenu.toggle($event)"
          [link]="true"
          [text]="true"
          class="relative"
          size="sm"
        >
          <ng-template #iconTemplate>
            <i
              class="text-bluegray-600 flex h-1_25rem w-1_25rem"
              inlineSVG="sprites.svg#notification-bell"
            ></i>
          </ng-template>
        </prime-button>
        <p-overlayPanel
          #notificationMenu
          [appendTo]="notificationBtn"
          styleClass="p-overlaypanel-center p-overlay-arrow-center py-2 w-14rem mt-0"
        >
          <div class="py-2 px-3 text-sm">You don't have any notifications.</div>
        </p-overlayPanel>
      </div>
      <prime-button
        (onClick)="navigateToUserSettings()"
        [link]="true"
        [text]="true"
        size="sm"
      >
        <ng-template #iconTemplate>
          <i
            class="text-bluegray-600 flex h-1_25rem w-1_25rem"
            inlineSVG="sprites.svg#cog"
          ></i>
        </ng-template>
      </prime-button>
      <!--  User dropdown -->
      <div
        #userBtn
        class="relative"
      >
        <prime-button
          (onClick)="userMenu.toggle($event)"
          [link]="true"
          [text]="true"
          styleClass="px-2 hover:text-white text-bluegray-600 transition-colors	transition-duration-300 transition-ease-in-out"
        >
          <ng-template #contentTemplate>
            <span class="mr-3 font-semibold">{{ currentUser()?.fullName }}</span>
            <img
              alt="profile picture"
              class="profile-pic"
              src="/assets/images/elements/user-profile.png"
            />
          </ng-template>
        </prime-button>
        <p-overlayPanel
          #userMenu
          [appendTo]="userBtn"
          styleClass="p-overlaypanel-right p-overlay-arrow-center p-2 w-13rem mt-0"
        >
          <prime-button
            (onClick)="userMenu.toggle($event); logout()"
            [text]="true"
            label="Logout"
            severity="secondary"
            size="sm"
            styleClass="w-full text-left font-semibold"
          >
          </prime-button>
          <hr class="my-2" />
          <div class="text-left px-2 py-0_75rem">Version No: {{ version$ | async }}</div>
        </p-overlayPanel>
      </div>
    </div>
  </div>
</nav>
<aside
  [ngClass]="{ 'aside-opened': menuOpen }"
  class="aside-menu-holder"
  id="main-aside"
>
  <a
    (click)="toggleMenu()"
    class="aside-menu-holder-trigger aside-item"
  >
    <i class="icon-pinnakl-menu"></i>
  </a>
  <ul class="aside-menu-list">
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/dashboard"
        title=""
      >
        <i
          class="icon-pinnakl-side-dashboard-active"
          title="Dashboard"
        ></i>
        <span>Dashboard</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/oms"
        title=""
      >
        <i
          class="icon-pinnakl-side-oms"
          title="OMS"
        ></i>
        <span>OMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/ems"
        title=""
      >
        <i
          class="icon-pinnakl-globe"
          title="EMS"
        ></i>
        <span>EMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/pms"
        title=""
      >
        <i
          class="icon-pinnakl-side-positions-active"
          title="PMS"
        ></i>
        <span>PMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/pricing"
        title=""
      >
        <i
          class="icon-pinnakl-side-pricing-active"
          title="Pricing"
        ></i>
        <span>Pricing</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/risk/risk-home"
        title=""
      >
        <i
          class="icon-pinnakl-side-risk-active"
          title="Risk"
        ></i>
        <span>Risk</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/reconciliation"
        title=""
      >
        <i
          class="icon-pinnakl-side-reconciliation-active-svg"
          title="Recon"
        ></i>
        <span>Reconciliation</span>
      </a>
    </li>

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/attribution"
        title="Attribution"
      >
        <i
          class="icon-pinnakl-side-pl-active"
          title="Attribution"
        ></i>
        <span>Attribution</span>
      </a>
    </li>

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/reporting/all-reports"
        title=""
      >
        <i
          class="icon-pinnakl-side-reports-active"
          title="Reports"
        ></i>
        <span>Reports</span>
      </a>
    </li>
    <li
      *ngIf="showIncompleteScreens()"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/shadow-nav/shadow-nav-home"
        title=""
      >
        <i
          class="icon-pinnakl-shadow-nav-active"
          title="Shadow NAV"
        ></i>
        <span>Shadow NAV</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/securities"
        title=""
      >
        <i
          class="icon-pinnakl-side-securities-active"
          title="Securities"
        ></i>
        <span>Securities</span>
      </a>
    </li>

    <li
      *ngIf="includeTesting"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/testing"
        title=""
      >
        <i
          class="icon-pinnakl-side-securities-active"
          title="Testing"
        ></i>
        <span>Testing</span>
      </a>
    </li>

    <!-- <li routerLinkActive="active">
                    <a routerLink="/rebalancing/rebalancing-home" class="tooltip" title="">
                      <i title="Rebalancing" class="icon-pinnakl-rebalance"></i>
                      <span>Rebalancing</span>
                    </a>
                  </li> -->

    <li routerLinkActive="active">
      <a
        class="tooltip flex"
        routerLink="/files"
        title="Files"
      >
        <i
          class="flex h-1_25rem w-1_25rem ml-0_125rem"
          inlineSVG="sprites.svg#file"
          title="files"
        ></i>
        <span>Files</span>
      </a>
    </li>

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/rebalancing"
        title=""
      >
        <i
          class="icon-pinnakl-rebalance"
          title="Modeling"
        ></i>
        <span>Rebalancing</span>
      </a>
    </li>

    <li
      *ngIf="false"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/cash-flow"
        title=""
      >
        <i
          class="custom-icon-cashflow position-icon"
          title="Cash Flow"
        ></i>
        <span>Cash Flow</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/stock-loan"
        title=""
      >
        <i
          class="custom-icon-stock-loan position-icon"
          title="Stock Loan"
        ></i>
        <span>Stock Loan</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/corporate-actions"
        title=""
      >
        <i
          class="custom-icon-corporate-action position-icon"
          title="Corporate Actions"
        ></i>
        <span>Corporate Actions</span>
      </a>
    </li>
    <li
      *ngIf="showIncompleteScreens()"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/reporting/manager-reports"
        title=""
      >
        <i
          class="icon-pinnakl-security-add-manually"
          title="Manager Reports"
        ></i>
        <span>Manager Reports</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip flex"
        routerLink="/configuration"
        title="Configuration"
      >
        <i
          class="flex h-1_25rem w-1_25rem ml-0_125rem"
          inlineSVG="sprites.svg#cog"
        ></i>
        <span>Configuration</span>
      </a>
    </li>
    <li
      *ngIf="isNotProduction"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/api-playground"
        title=""
      >
        <i class="icon-pinnakl-fire"></i>
        <span>API Playground</span>
      </a>
    </li>
  </ul>
</aside>

<main
  [ngClass]="{ 'aside-opened': menuOpen }"
  class="main-holder"
>
  <pinnakl-spinner></pinnakl-spinner>
  <reconnect-window></reconnect-window>
  <default-confirm-action></default-confirm-action>
  <router-outlet></router-outlet>
</main>

<div kendoDialogContainer></div>
<div kendoWindowContainer></div>

<div
  class="atdl-content"
  id="reactDropdown"
></div>
