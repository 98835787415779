@if (dropdownPlaceholderIconTemplate) {
  <div class="relative">
    <div class="ml-2 absolute left-0 top-50 dropdown-placeholder-icon z-1">
      <ng-container *ngTemplateOutlet="dropdownPlaceholderIconTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="dropdown"></ng-container>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="dropdown"></ng-container>
}

<ng-template #dropdown>
  <p-dropdown
    (onBlur)="onBlur.emit($event)"
    (onChange)="onChange.emit($event)"
    (onClear)="onClear.emit($event)"
    (onClick)="onClick.emit($event)"
    (onFilter)="onFilter.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onHide)="onHide.emit($event)"
    (onLazyLoad)="onLazyLoad.emit($event)"
    (onShow)="onShow.emit($event)"
    [appendTo]="appendTo"
    [ariaFilterLabel]="ariaFilterLabel"
    [ariaLabel]="ariaLabel"
    [ariaLabelledBy]="ariaLabelledBy"
    [autofocusFilter]="autofocusFilter"
    [autofocus]="autofocus"
    [dataKey]="dataKey"
    [disabled]="disabled"
    [dropdownIcon]="dropdownIcon"
    [editable]="editable"
    [emptyFilterMessage]="emptyFilterMessage"
    [emptyMessage]="emptyMessage"
    [filterBy]="filterBy"
    [filterLocale]="filterLocale"
    [filterMatchMode]="filterMatchMode"
    [filterPlaceholder]="filterPlaceholder"
    [filterValue]="filterValue"
    [filter]="filter"
    [formControl]="$any(formControl)"
    [group]="group"
    [inputId]="inputId"
    [lazy]="lazy"
    [loading]="loading"
    [maxlength]="maxlength"
    [name]="name"
    [ngClass]="dropdownClasses"
    [optionDisabled]="optionDisabled"
    [optionGroupChildren]="optionGroupChildren"
    [optionGroupLabel]="optionGroupLabel"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="options"
    [overlayOptions]="overlayOptions"
    [panelStyleClass]="panelStyleClass"
    [panelStyle]="panelStyle"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [resetFilterOnHide]="resetFilterOnHide"
    [scrollHeight]="scrollHeight"
    [showClear]="showClear"
    [styleClass]="styleClass"
    [style]="style"
    [tabindex]="tabindex"
    [tooltipPositionStyle]="tooltipPositionStyle"
    [tooltipPosition]="tooltipPosition"
    [tooltipStyleClass]="tooltipStyleClass"
    [tooltip]="tooltip"
    [virtualScrollItemSize]="virtualScrollItemSize"
    [virtualScrollOptions]="virtualScrollOptions"
    [virtualScroll]="virtualScroll"
  >
    @if (selectedItemTemplate) {
      <ng-template
        let-selectedItem
        pTemplate="selectedItem"
      >
        <ng-container
          *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: selectedItem }"
        ></ng-container>
      </ng-template>
    }
    @if (itemTemplate) {
      <ng-template
        let-item
        pTemplate="item"
      >
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      </ng-template>
    }
    @if (dropdownIconTemplate) {
      <ng-template
        let-item
        pTemplate="dropdownicon"
      >
        <ng-container *ngTemplateOutlet="dropdownIconTemplate"></ng-container>
      </ng-template>
    }
    @if (footerTemplate) {
      <ng-template
        let-item
        pTemplate="footer"
      >
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </ng-template>
    }
    @if (emptyTemplate) {
      <ng-template
        let-item
        pTemplate="empty"
      >
        <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
      </ng-template>
    }
  </p-dropdown>
</ng-template>

<ng-container *ngTemplateOutlet="validationContent"></ng-container>
